import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import {document}
// import { BLOCKS, MARKS } from "@contentful/rich-text-types"

class BlogSlider1 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        // {
        //   breakpoint: 480,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //   },
        // },
      ],
    }
    const imgEffect = this.props.imgEffect || ""
    const blogPostCls =
      this.props.blogPostCls ||
      "blog-grid blog-rounded blog-effect1 post-style-3"

    // const options = {
    //   renderMark: {
    //     [MARKS.BOLD]: text => <span className="font-weight-bold">{text}</span>,
    //   },
    //   renderNode: {
    //     [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    //     [BLOCKS.HEADING_2]: (node, children) => (
    //       <h2 className="font-weight-bold">{children}</h2>
    //     ),
    //   },
    // }

    console.log(this.props)

    return (
      <>
        <Slider {...settings} className="blog-carousel btn-style-1">
          {this.props.data.map((item, id) => (
            <div class="item">
              <div class={`blog-post ${blogPostCls}`}>
                <div class={`dlab-post-media ${imgEffect}`}>
                  {" "}
                  <GatsbyImage image={item.images.gatsbyImageData} />
                </div>
                <div class="dlab-post-info info-news">
                  <div class="dlab-post-meta">
                    <ul>
                      <li class="post-date">{item.newsEventsDate}</li>
                    </ul>
                  </div>
                  {/* <div class="dlab-post-title" style={{ minHeight: "150px" }}> */}
                  <div class="dlab-post-title">
                    <h4 class="post-title">{item.title}</h4>
                  </div>
                  <div class="dlab-post-text">
                    {/* <p>{item.descSingkat}</p> */}
                  </div>
                  {!this.props.noDes && <div class="dlab-post-text"></div>}
                  <div class="dlab-post-readmore">
                    <Link
                      to={`/news-page/${item.slug}`}
                      title="READ MORE"
                      rel="bookmark"
                      class="site-button blogbtn btnhover14"
                    >
                      READ MORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default BlogSlider1
