const content = [
  {
    author: `Zhao Yanqing`,
    designation: `President Director of Kexing Biopharm Co. Ltd`,
    description: `As a leading pharmaceutical enterprise in Southeast Asia, Innogene Kalbiotech (IGK) is one of our most important business partner in Southeast Asia, maintained a mutually beneficial relationship with us for about 20 years. IGK is a good partner with honest and practical style, I believe that we will continue to go hand in hand and expand the scope of cooperation in the future to make a contribution to Indonesia and the whole human health with the support of the friendship bridge between China and Indonesia.`,
  },
  {
    author: `Du Kai`,
    designation: `CEO of Gan & Lee Pharmaceuticals`,
    description: `Gan & Lee has been cooperating with IGK since 2011. With our joint efforts, Ezelin® has successfully been launched in Indonesia. Although both teams ever faced a lot of difficulties in many collaborative projects, IGK team always try their best to seek solutions and solve every problem carefully with the spirit of science. They are one of the most professional teams we have ever seen. We considerably value our partnership and also believe that Gan & Lee and IGK will usher in brighter win-win cooperation in the future.`,
  },
  {
    author: `Gladys Wang`,
    designation: `Head of Process Solutions, South Asia & Oceania Merck Chemicals And Life Sciences`,
    description: `Merck has been partnering with Innogene Kalbiotech (IGK) since 2016 to accelerate the development of the biotechnology medicine and manufacture of APIs of GMP standard locally in Indonesia. We share a common purpose to improve the public health and address unmet medical needs of the Indonesian people. IGK possesses a multi-disciplinary team, from R&D, manufacturing to regulatory with rich expertise in emerging technologies to improve research and development productivity. In our years of partnership, Merck has provided training and knowledge transfer in the areas of upstream, downstream to formulation application to enhance the capabilities of IGK’s scientists and engineers, and worked closely with IGK to explore innovative solutions to further optimize the API development and manufacturing process.`,
  },
  {
    author: `Susheel Umesh`,
    designation: `Chief Commercial Officer, Emerging Markets, Biocon Biologics`,
    description: `Biocon Biologics is driven with a sense of purpose to enable equitable access to high quality affordable biologics & transforming lives through meaningful impact.

    Our decade long relationship with Innogene Kalbiotech rests on a strong foundation of a shared purpose and has been built on the core values of collaboration, mutual respect and the passion to serve patients through affordable life saving biologics, enabling them to live a healthier Life. We are glad that through Kalbe we are able to address unmet needs of breast cancer patients through our affordable bTrastuzumab which is a leading brand in its segment in Indonesia.  

    We look forward to strengthening this synergistic partnership and expanding our product portfolio to enhance access to patients, in Indonesia.`,
  },
]

export default content
