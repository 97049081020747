import React, { Component } from "react"
import { Link } from "gatsby"

import "../../css/custom-css/custom-footer.css"
// import logoLight from "../../images/kalbe-logo.png"
import logoCream from "../../images/logo-innogene-kalbiotech.png"
import FooterGreen from "./FooterGreen"
import { Phone, Mail } from "react-feather"

class FooterCream extends Component {
  render() {
    return (
      <>
        <footer className="site-footer style1">
          <div className="footer-top custom-footer-color">
            <div className="container">
              <div className="footer-content">
                <div className="row justify-content-around">
                  <div className="col-md-4 col-sm-6">
                    <div className="widget widget_about">
                      <h4 className="footer-title">
                        <img
                          className="footer-logo-custom"
                          src={logoCream}
                          alt=""
                        />
                      </h4>
                      <p className="footer-content">
                        21 Bukit Batok Crescent #28-79
                        <br />
                        WCEGA Tower Singapore 658065
                        <br />
                        <Phone size={16} color="#7AA613" className="mr-2" /> +65
                        6501-0400
                        <Mail
                          size={16}
                          color="#7AA613"
                          className="ml-3 mr-2"
                        />{" "}
                        info.igk@innogene.com.sg
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#ff0000"
                          class="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg> */}
                      </p>
                    </div>
                  </div>
                  <div class="about col-md-4 col-sm-6">
                    <div class="widget">
                      <div className="footer-links">
                        <h4 className="footer-title p-t40">About Us</h4>
                        <ul className="list-2">
                          <li className="custom responsiveFooter">
                            <Link to={"/about"}>About IGK</Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <a
                              href="https://www.kalbio.co.id/"
                              target="_blank"
                            >
                              About Kalbio
                            </a>
                          </li>
                          <li className="custom responsiveFooter">
                            <a
                              href="https://www.kalbe.co.id/en/"
                              target="_blank"
                            >
                              About Kalbe
                            </a>
                          </li>
                          <li className="custom responsiveFooter">
                            <a
                              href="https://www.kg-biologics.com/"
                              target="_blank"
                            >
                              About KGBio
                            </a>
                          </li>
                          {/* <li className="custom responsiveFooter">
                            <Link to={"/about"}>About Innogene</Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link to="">Recognitions</Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link to="">Our Leadership Team</Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link to="">Our Stakeholders</Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="our-services col-md-4 col-sm-6">
                    <div class="widget">
                      <div className="footer-links">
                        <h4 className="footer-title p-t40">Our Services</h4>
                        <ul className="list-2">
                          <li className="custom">
                            <Link
                              to={"/products-and-services/services/diagnostics"}
                            >
                              Diagnostics
                            </Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link
                              to={
                                "/products-and-services/services/therapeutics"
                              }
                            >
                              Therapeutics
                            </Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link
                              to={
                                "/products-and-services/services/other-services"
                              }
                            >
                              Other Services
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FooterGreen />
        </footer>
      </>
    )
  }
}

export default FooterCream
