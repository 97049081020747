import React, { Component } from 'react';
import { Link } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kalbelogo from "../images/kalbe-logo.png"
import AbdiWaluyo from "../images/partners/AbdiWaluyo.png"
import AngsaMerah from "../images/partners/AngsaMerah.jpg"
import Balimed from "../images/partners/Balimed.png"
import BundaMargonda from "../images/partners/BundaMargonda.png"
import Carolus from "../images/partners/Carolus.jpg"
import ColumbiaAsia from "../images/partners/ColumbiaAsia.png"
import Emp from "../images/partners/Emp.png"
import Family from "../images/partners/Family.jpg"
import Fatmawati from "../images/partners/Fatmawati.png"
import GrandFamily from "../images/partners/GrandFamily.png"
import Hermina from "../images/partners/Hermina.png"
import Innolab from "../images/partners/Innolab.png"
import Kardinah from "../images/partners/Kardinah.png"
import Klungkung from "../images/partners/Klungkung.png"
import Labklinik from "../images/partners/Labklinik.png"
import Mayapada from "../images/partners/Mayapada.png"
import MetropolitanMedicalCentre from "../images/partners/MetropolitanMedicalCentre.png"
import MitraKeluarga from "../images/partners/MitraKeluarga.png"
import Mitrasana from "../images/partners/Mitrasana.png"
import OnkologiSurabaya from "../images/partners/OnkologiSurabaya.png"
import PermataCibubur from "../images/partners/PermataCibubur.jpg"
import PIK from "../images/partners/AngsaMerah.jpg"
import PondokIndahGroup from "../images/partners/PondokIndahGroup.jpg"
import Restu from "../images/partners/Restu.png"
import Siloam from "../images/partners/Siloam.png"
import Tambak from "../images/partners/Tambak.jpg"
import YKI from "../images/partners/YKI.png"
import YPKMandiri from "../images/partners/YPKMandiri.jpg"

const content = [
    {
        logo: Innolab,
    },
    {
        logo: Labklinik,
    },
    {
        logo: YKI,
    },
    {
        logo: MitraKeluarga,
    },
    {
        logo: Hermina,
    },
    {
        logo: PIK,
    },
    {
        logo: Family,
    },
    {
        logo: GrandFamily,
    },
    {
        logo: PondokIndahGroup,
    },
    {
        logo: Siloam,
    },
    {
        logo: MetropolitanMedicalCentre,
    },
    {
        logo: Mayapada,
    },
    {
        logo: Tambak,
    },
    {
        logo: ColumbiaAsia,
    },
    {
        logo: OnkologiSurabaya,
    },
    {
        logo: Mitrasana,
    },
    {
        logo: AngsaMerah,
    },
    {
        logo: Carolus,
    },
    {
        logo: BundaMargonda,
    },
    {
        logo: YPKMandiri,
    },
    {
        logo: PermataCibubur,
    },
    {
        logo: AbdiWaluyo,
    },
    {
        logo: Balimed,
    },
    {
        logo: Fatmawati,
    },
    {
        logo: Kardinah,
    },
    {
        logo: Restu,
    },
    {
        logo: Klungkung,
    },
    {
        logo: Emp,
    },
]


class ClientSlider1 extends Component {

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 4
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 3,
                    slidesToScroll: 3
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
                    slidesToScroll: 2,
					slidesToShow: 2
				  }
				}
			]
        };

        const Border = this.props.hideBorder ? "" : "border"
        const padding = this.props.allowPadding ? "p-a25":""

        return (
            <>
                <Slider {...settings} className="client-logo-carousel btn-style-1 icon-2">
                    {content.map((item, id) => (
                        <div class="item">
                            <div class={`ow-client-logo ${padding}`}>
                                <div class={`client-logo ${Border}`}>
                                    <Link to="#"><img src={item.logo} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default ClientSlider1;