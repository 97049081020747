import React, { useState, Component } from "react"
import { Link } from "gatsby"
import Sticky from "react-stickynode"
import logo from "../images/kalbe-logo.png"
import logoIK from "../images/logo-innogene-kalbiotech.png"
import "../css/custom-css/custom-header.css"

class Header extends Component {
  componentDidMount() {
    // var searchBtn = document.getElementById("quik-search-btn")
    // var searchPopup = document.querySelector(".dlab-quik-search")
    // var closeBtn = document.getElementById("quik-search-remove")

    // searchBtn.addEventListener('click', function () {
    //     searchPopup.style.display = "block"
    //     searchPopup.classList.add("On")
    // })

    // closeBtn.addEventListener('click', function () {
    //     searchPopup.style.display = "none"
    //     searchPopup.classList.remove("On")
    // })

    // sidebar open/close

    var btn = document.querySelector(".navicon")
    var nav = document.querySelector(".header-nav")

    function toggleFunc() {
      btn.classList.toggle("open")
      nav.classList.toggle("show")
    }

    btn.addEventListener("click", toggleFunc)

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    )
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this)
      })
    }

    function checkLi(current) {
      const active = current.classList.contains("open")
      navUl.forEach(el => el.classList.remove("open"))
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open")
        console.log("active")
      } else {
        current.classList.add("open")
        console.log("close")
      }
    }
  }
  render() {
    return (
      <>
        <header class="site-header mo-left header">
          <Sticky innerZ={999} enabled={true}>
            <div class="sticky-header main-bar-wraper navbar-expand-lg">
              <div class="main-bar clearfix ">
                <div class="container clearfix">
                  <div class="logo-header mostion">
                    <Link to={"/"}>
                      <img src={logoIK} alt="" />
                    </Link>
                  </div>

                  <button
                    class="navbar-toggler navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div
                    class={`header-nav navbar-collapse collapse justify-content-end`}
                    id="navbarNavDropdown"
                  >
                    <div class="logo-header d-md-block d-lg-none">
                      <Link to={"/"}>
                        <img src={logoIK} alt="" />
                      </Link>
                    </div>
                    <ul class="nav navbar-nav">
                      <li
                        class={
                          this.props.indexPage
                            ? "active has-mega-menu homedemo"
                            : "has-mega-menu homedemo"
                        }
                      >
                        {" "}
                        <Link to={"/"}>
                          Home<i></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/about"}>
                          About Us <i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          <li>
                            <Link to={"/about/#innogene"}>About Innogene</Link>
                          </li>
                          <li>
                            <Link to={"/about/#recognitions"}>
                              Recognitions
                            </Link>
                          </li>
                          <li>
                            <Link to={"/about/#our-leader"}>
                              Our Leadership Team
                            </Link>
                          </li>
                          <li>
                            <Link to={"/about/#other-companies"}>
                              Our Stakeholders
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li
                        class={
                          this.props.productsPage
                            ? "active has-sub-menu"
                            : "has-sub-menu"
                        }
                      >
                        <Link to={"/products-and-services"}>
                          Products and Services
                          <i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          {/* <li>
                            <Link to={"/products-and-services/services/cmo"}>
                              CMO
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/products-and-services/services/toll-in-testing"
                              }
                            >
                              Toll-in Testing
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/products-and-services/services/process-analytical-development"
                              }
                            >
                              Process and Analytical Development
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to={"/products-and-services/services/diagnostics"}
                            >
                              Diagnostics
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/products-and-services/services/therapeutics"
                              }
                            >
                              Therapeutics
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/products-and-services/services/other-services"
                              }
                            >
                              Other Services
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li
                        class={
                          this.props.careerPage
                            ? "active has-sub-menu"
                            : "has-sub-menu"
                        }
                      >
                        <Link to={"/experties-and-experience"}>
                          Expertise & Experience
                          <i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          <li>
                            <Link
                              to={
                                "/experties-and-experience/expertise/product-to-market"
                              }
                            >
                              Product To Market
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/experties-and-experience/expertise/our-reach"
                              }
                            >
                              Our Reach
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/experties-and-experience/expertise/our-partners"
                              }
                            >
                              Our Partners
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/experties-and-experience/expertise/regulatory"
                              }
                            >
                              Regulatory
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/experties-and-experience/expertise/research"
                              }
                            >
                              Research
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li class="has-mega-menu">
                        {" "}
                        <Link to={"/news-page/news-event"}>
                          News<i></i>
                        </Link>
                      </li> */}
                      <li
                        class={
                          this.props.contactUsPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={"/partnership"}>Partnership</Link>
                      </li>
                      <li
                        class={
                          this.props.careerPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={"/career-page"}>
                          Career<i></i>
                        </Link>
                      </li>
                      <li
                        class={
                          this.props.contactUsPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={"/news-page/news-event"}>
                          News and Events
                        </Link>
                      </li>
                      <li
                        class={
                          this.props.contactUsPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={"/contact-us"}>Contact Us</Link>
                      </li>
                      {/* <li>
                        <Link
                          to="https://login.onekalbe.com/KalbeProdLDAP1/?authn_try_count=0&contextType=external&username=string&contextValue=%2Foam&password=sercure_string&challenge_url=https%3A%2F%2Flogin.onekalbe.com%2FKalbeProdLDAP1%2F&ssoCookie=disablehttponly&request_id=4026290567095873144&locale=en_US&resource_url=https%253A%252F%252Fwww.onekalbe.com%252FAdmin%252FSSOLogin%253Freturnurl%253D%25252f#/login"
                          className="fa fa-user"
                        ></Link>
                      </li> */}
                      {/* <li
                        class={
                          this.props.careerPage
                            ? "active has-sub-menu"
                            : "has-sub-menu"
                        }
                      >
                        <Link>
                          More
                          <i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          <li>
                            <Link to={"/leading-in-asia"}>
                              Leading the way in Asia
                            </Link>
                          </li>
                          <li>
                            <Link to={""}>Career</Link>
                          </li>
                          <li>
                            <Link to={"/news-page/news-event"}>News</Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    )
  }
}
export default Header
