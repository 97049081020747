import React from "react"

function FooterGreen() {
  return (
    <div class="footer-bottom footer-line">
      <div class="container">
        <div class="footer-bottom-in">
          <div class="footer-bottom-text">
            <p className="copyright-text">
              Copyright 2022 &copy; Innogene Kalbiotech. All Rights Reserved
            </p>
          </div>
          <div class="footer-bottom-social">
            <ul class="dlab-social-icon dez-border">
              <li>
                <a
                  href="https://id.linkedin.com/company/innogene-kalbiotech-pte-ltd-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-linkedin"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterGreen
