import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class ProductsSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    const imgEffect = this.props.imgEffect || ""
    const blogPostCls =
      this.props.blogPostCls ||
      "blog-grid blog-rounded blog-effect1 post-style-3"

    return (
      <>
        <Slider {...settings} className="blog-carousel btn-style-1">
          {this.props.data.map((item) => (
            <div class="item">
              <div class={`blog-post ${blogPostCls}`}>
                <div class={`dlab-post-media ${imgEffect}`}>
                  <GatsbyImage image={item.image.gatsbyImageData} />
                </div>
                <div class="dlab-post-info">
                  <div class="dlab-post-title" style={{ minHeight: "150px" }}>
                    <h4 class="post-title">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                  <div class="dlab-post-readmore">
                    <Link
                      to={`/products-and-services/services/${item.slug}`}
                      class="site-button blogbtn btnhover14"
                    >
                      READ MORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default ProductsSlider
